import React from 'react';
import styles from './Welcome.module.css'; // Import CSS Module
import logo from '@/assets/logo.png';
import banner from '@/assets/banner.png';
import allMyLinksIcon from '@/assets/icons/AllMyLinks.png';

const Welcome = () => {
    return (
        <div className={styles.welcomeContainer}>
            <div className={styles.welcomeHeader}>
                <div className={styles.welcomeLogoContainer}>
                    <img src={logo} alt="Logo" className={styles.welcomeLogo}/>
                </div>
                <div className={styles.welcomeSloganContainer}>
                    <h1 className={styles.welcomeSlogan}>The Goddess Of Lust's Private Parts</h1>
                    <h2 className={styles.welcomeSlogan}>The worlds best nerdy redhead goth deepthroat anal slut.</h2>
                </div>
            </div>

            <div className={styles.welcomePageHeader}>Cumming Soon</div>

            <div className={styles.welcomePageDescription}>Check the Private Parts of TheGoddessOfLust weekly for updates.</div>

            <div className={styles.welcomeContentWrapper}>
                <div className={styles.welcomeContent}>
                    <div className={styles.welcomeBlog}>
                        <div className={styles.welcomeMessage}>
                            Welcum to The Goddess Of Lust's Private Parts
                        </div>
                        <div className={styles.welcomeDetails}>
                            Currently, making content whenever the fuck I feel like it & of whatever the fuck I feel like.
                            Customs closed until further notice.
                            If you would like to purchase any of my existing content, talk to me, & see exclusive content, join my FREE Discord Server.
                        </div>

                        <div className={styles.welcomeContact}>
                            <div className={styles.welcomeContactIconWrapper}>
                                <img src={allMyLinksIcon} alt={"All My Links"} className={styles.welcomeContactIcon}/>
                            </div>
                            <div className={styles.welcomeContactLink}>
                                <a href={"https://allmylinks.com/lustofgodess"} target={"_blank"}>All My Links</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img src={banner} alt="Banner" className={styles.welcomeBanner}/>
        </div>
    );
};

export default Welcome;
