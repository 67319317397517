import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import axios from '@/api/axios';

// Define types for user and context value
interface User {
    username: string;
    email: string | null;
    phone: string | null;
    name: string | null;
    verified: boolean;
}

interface AuthContextType {
    user: User | null;
    loading: boolean;
    login: (usernameOrEmailOrPhone: string, password: string) => Promise<void>;
    signup: (usernameOrEmailOrPhone: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    forgotPassword: (usernameOrEmailOrPhone: string) => Promise<void>;
}

// Define default context value with proper typing
const defaultAuthContext: AuthContextType = {
    user: null,
    loading: false,
    login: async () => {},
    signup: async () => {},
    logout: async () => {},
    forgotPassword: async () => {},
};

export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(false);
    const secondsBeforeTokenDeathToKeepAlive = 50;

    const clearTokens = () => {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_expires_in');
    };

    const scheduleRefreshToken = useCallback((refreshTokenCallback: CallableFunction) => {
        let expiresIn = localStorage.getItem('access_expires_in');

        if(!expiresIn){
            console.log("No refresh token available; user might not be logged in.");
            return;
        }

        const timeoutSeconds = (parseInt(expiresIn) - secondsBeforeTokenDeathToKeepAlive);
        const timeoutMilliseconds = timeoutSeconds * 1000;
        setTimeout(refreshTokenCallback, timeoutMilliseconds);
    }, [secondsBeforeTokenDeathToKeepAlive]);

    const refreshToken = useCallback(async () => {
        try {
            const token = localStorage.getItem('access_token');
            const refresh_token = localStorage.getItem('refresh_token');

            if (!refresh_token) {
                console.log("No refresh token available; user might not be logged in.");
                return;
            }

            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
            const response = await axios.post('/auth/keep-alive', { refresh_token });
            const { access_token, access_expires_in, refresh_token: newRefreshToken } = response.data.data;

            localStorage.setItem('refresh_token', newRefreshToken);
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('access_expires_in', access_expires_in.toString());
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

            scheduleRefreshToken(refreshToken);
        } catch (error) {
            console.error('Error refreshing token:', error);
            clearTokens();
        }
    }, [scheduleRefreshToken]);

    const addIdentifier = (usernameOrEmailOrPhone: string) => {
        let isPhone = (usernameOrEmailOrPhone[0] === '+'),
            isEmail = usernameOrEmailOrPhone.includes('@'),
            isNumeric = /^\d+$/.test(usernameOrEmailOrPhone);

        return (
            isNumeric ? {
                phone: '+'+usernameOrEmailOrPhone
            } : (
                isPhone ? {
                    phone: usernameOrEmailOrPhone
                } : (isEmail ? {
                    email: usernameOrEmailOrPhone
                } : {
                    username: usernameOrEmailOrPhone
                }))
        );
    }

    const login = async (usernameOrEmailOrPhone: string, password: string) => {
        setLoading(true);
        try {
            let data = {
                ...addIdentifier(usernameOrEmailOrPhone),
                password
            };

            const response = await axios.post('/auth/login', data);
            const { refresh_token, access_token, access_expires_in, user: userData } = response.data.data;
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('access_expires_in', access_expires_in.toString());
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            setUser(userData);
            setLoading(false);
            scheduleRefreshToken(refreshToken);
        } catch (error){
            console.error('Login failed:', error);
            setLoading(false);
        }
    };

    const signup = async (usernameOrEmailOrPhone: string, password: string) => {
        setLoading(true);
        try {
            let data = {
                ...addIdentifier(usernameOrEmailOrPhone),
                password
            };

            const response = await axios.post('/auth/signup', data);
            const { refresh_token, access_token, access_expires_in, user: userData } = response.data.data;
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('access_expires_in', access_expires_in.toString());
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            setUser(userData);
            setLoading(false);
            scheduleRefreshToken(refreshToken);
        } catch (error){
            console.error('Signup failed:', error);
            setLoading(false);
        }
    };

    const logout = async () => {
        setLoading(true);
        try {
            const refresh_token = localStorage.getItem('refresh_token');
            clearTokens();
            await axios.post('/auth/logout', {refresh_token});
            setUser(null);
            delete axios.defaults.headers.common['Authorization'];
            setLoading(false);
        } catch (error){
            console.error('Logout failed:', error);
            setLoading(false);
        }
    };

    const forgotPassword = async (usernameOrEmailOrPhone: string) => {
        setLoading(true);
        try {
            let data = {
                    ...addIdentifier(usernameOrEmailOrPhone)
                };

            await axios.post('/auth/forgot/password', data);
            setLoading(false);
        } catch (error){
            console.error('Forgot Password failed:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');

        if (token) {
            setLoading(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            axios.get('/auth/me')
                .then(response => {
                    setUser(response.data);
                    setLoading(false);
                })
                .then(() => {
                    scheduleRefreshToken(refreshToken);
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                    clearTokens();
                });
        } else {
            setLoading(false);
        }
    }, [setUser, setLoading, scheduleRefreshToken, refreshToken]);

    return (
        <AuthContext.Provider value={{ user, loading, login, signup, logout, forgotPassword }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);